export const searchDebounceDelay = 300;
export const checkShowExhibitionDate = false;
export const selfOrigin = 'https://viewingroom.frieze.com';

export type BackgroundConfig = {
  width: number;
  height: number;
  rationPxCm: number;
  wallHeightPx: number;
  backgroundPositionX: number | null;
  topPadding: number;
  backgroundImage: string;
};

const backgroundConfigBase = {
  width: 5699,
  height: 3000
};

const baseBackgroundConfigNear = {
  ...backgroundConfigBase,
  wallHeightPx: 2070,
  topPadding: 0
};

export const backgroundConfigFar: Omit<BackgroundConfig, 'backgroundImage'> = {
  ...backgroundConfigBase,
  wallHeightPx: 2700,
  topPadding: -100,
  backgroundPositionX: -170,
  rationPxCm: 6
};

export const backgroundConfigNear: Omit<BackgroundConfig, 'backgroundImage'> = {
  ...baseBackgroundConfigNear,
  rationPxCm: 9.4,
  wallHeightPx: 2700,
  topPadding: -100,
  backgroundPositionX: -270
};

export const wsAnalyticsHost =
  process.env.NODE_ENV !== 'production'
    ? 'dev.viewingroom.frieze.com'
    : window.location.host;

export const paginationLimit = 20;
export const jwPlayerScript = 'https://cdn.jwplayer.com/libraries/yKWvOSbH.js';

// Dev - 2
// Staging - 2
// Production - 10
export const FMFairId = 2;

export const FLFairId = 1;

export const WedVipAccessId = 1;
export const ThurVipAccessId = 2;
export const PublicUserAccessId = 3;

export const editAccountInfoLink = 'https://www.frieze.com/account/edit';
export const vipProgrammingLink =
  'https://www.frieze.com/fairs/frieze-los-angeles/program';

export const wedVipProgrammeLink =
  'https://www.frieze.com/fairs/frieze-viewing-room/programme/vip-programme?location=in-london';

export const friezeWeekLink = 'https://www.frieze.com/frieze-week';

export const pdfViewrLink = '/pdf-viewer.html';
export const welcomeFriezeLink =
  'https://www.frieze.com/article/welcome-FVR-victoria-siddall-and-claudio-de-sanctis';

export const partnersSectionId = 10;
export const iosAppLink =
  'https://apps.apple.com/ru/app/frieze-viewing-room/id1500340570';
export const androidAppLink =
  'https://play.google.com/store/apps/details?id=com.frieze.viewingroom';

export const getAppBannerPath = '/a2e84063-a49c-4e3b-9fc7-8a09918213ef';

export const partnersLink = '/';

export const dbLink = 'https://www.deutsche-bank.de';

export const VorticGalleries = [

    {
      id: '908', vorticLink: `https://embed-v4.vortic.io/#e=4574&bannerOnly=true&collapsed=true`,
      artworks: new Map<string, string>([
        ['15820', '9465'],
        ['15948', '9512'],
        ['15829', '9612'],
        ['15991', '9620'],
        ['16215', '9628'],
        ['15845', '9639'],
        ['15873', '9467'],
      ])


    },

    {
      id: '1211', vorticLink: `https://embed-v4.vortic.io/#e=4574&bannerOnly=true&collapsed=true`,
      artworks: new Map<string, string>([
        [ '20825', '9556' ],
        [ '21596', '9559' ],
        [ '21594', '9558' ],
        [ '21569', '9550' ],
        [ '21597', '9554' ],
        [ '20846', '9560' ],
        [ '21545', '9555' ],
        [ '20909', '9557' ],
        [ '21579', '9564' ],
        [ '20853', '9563' ],
        [ '20839', '9561' ],
        [ '20911', '9622' ],
        [ '21599', '9624' ],
        [ '20825', '9625' ]
      ])
    },
  ]
;
