import { debounce } from "debounce";
import useUpdateQueryState from "features/Navigation/hooks/useUpdateQueryState";
import useShallowSearchQuery from "features/Search/hooks/useShallowSearchQuery";
import History from "history";
import { ChangeEvent, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { RootState } from "store/rootReducer";
import {
  clearSearchString,
  setSearchActive,
  setSearchString,
  setShowResults,
} from "../../../components/SearchOverlay/store/searchOverlaySlice";
import { SearchFilters, SearchSections } from "../interfaces/search.interfaces";
import { searchRoute } from "../search.routes";

let aboartController: AbortController | undefined;

function aboart() {
  if (aboartController) {
    aboartController.abort();
    aboartController = undefined;
  }
}

export default function useShallowSearch() {
  const searchOverlayState = useSelector(
    (state: RootState) => state.searchOverlay,
    shallowEqual
  );

  const dispatch = useDispatch();

  const showSearch = useCallback(() => {
    dispatch(setSearchActive({ active: true }));
  }, [dispatch]);

  const { refetch, data, ...searchQuery } = useShallowSearchQuery(
    searchOverlayState.searchString
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const makeSearchRequestDebounced = useCallback(debounce(refetch, 400), [
    dispatch,
  ]);

  const hideSearch = useCallback(() => {
    dispatch(setSearchActive({ active: false }));
    makeSearchRequestDebounced.clear();
  }, [dispatch, makeSearchRequestDebounced]);

  const handleClearSearch = useCallback(() => {
    dispatch(clearSearchString());
  }, [dispatch]);

  const closeSearch = useCallback(() => {
    hideSearch();
    handleClearSearch();
  }, [handleClearSearch, hideSearch]);

  const showResults = useCallback(() => {
    dispatch(setShowResults({ show: true }));
  }, [dispatch]);

  const hideResults = useCallback(() => {
    dispatch(setShowResults({ show: false }));
  }, [dispatch]);

  const changeUiSearchString = useCallback(
    (searchString: string) => {
      dispatch(setSearchString({ searchString }));
    },
    [dispatch]
  );

  const { push } = useHistory();

  const match = useRouteMatch<{ section: SearchSections }>(searchRoute.path);

  const { getUpdatedLocation } = useUpdateQueryState();

  const deepSearch = useCallback(
    ({ name, ...filters }: Partial<SearchFilters> = {}) => {
      console.log(match)
      let section = match?.params?.section as SearchSections;
      let location: History.LocationDescriptor;
      name = name ?? searchOverlayState.searchString;
      if (filters.artists) {
        name = "";
      }
      
      const isSearchPage = !!match;
      
      location = getUpdatedLocation(
        { ...filters, name, skip: 0 },
        !isSearchPage
      );
      if (filters?.artists && !section) {
        section = "viewingRooms";
      }
      section = section || "artworks";

      push({
        ...location,
        pathname: searchRoute.link(section),
        state: { goBack: true },
      });

      hideResults();
      hideSearch();
    },
    [
      getUpdatedLocation,
      hideResults,
      hideSearch,
      match,
      push,
      searchOverlayState.searchString,
    ]
  );

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      aboart();
      const searchString = e.target.value;
      dispatch(setSearchString({ searchString }));
      if (!!searchString) {
        showResults();
        makeSearchRequestDebounced();
      } else {
        hideResults();
      }
    },
    [dispatch, hideResults, makeSearchRequestDebounced, showResults]
  );

  const hasResult =
    data?.artworks.length || data?.viewingRooms.length || data?.artists.length;

  return {
    searchOverlayState,
    showSearch,
    hideSearch,
    handleSearchChange,
    handleClearSearch,
    deepSearch,
    closeSearch,
    searchQuery: { ...searchQuery, data },
    hasResult,
    showResults,
    hideResults,
    changeUiSearchString,
  };
}
