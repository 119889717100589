import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import clsx from "clsx";
import Button from "components/Button/Button";
import SvgArrowTiny from "components/icons/ArrowTiny";
import { NestedMenuHOC } from "components/NestedMenu/NestedMenu";
import NestedMenuItem from "components/NestedMenu/NestedMenuItem";
import Svg from "components/Svg";
import React, { useCallback } from "react";
import { Field, Form } from "react-final-form";
import em from "styles/utils/em";
import useFiltersMenu from "../hooks/useFiltersMenu";
import useSearch from "../hooks/useSearch";
import useSearchState from "../hooks/useSearchState";
import useShallowSearch from "../hooks/useShallowSearch";
import { SearchFilters } from "../interfaces/search.interfaces";
import {
  FilterCheckbox as Checkbox,
  FilterCheckboxLabel as CheckboxLabel,
} from "../SearchFilters/components/FilterCheckbox";

const StyledForm = styled.form`
  padding-bottom: 30px;
`;

const listItemCss = css`
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;

  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: ${em("0.86px", "13px")};
`;

const ListItemWrapper = styled.div`
  border-bottom: 1px solid #e4e4e4;
`;

const ArrowSvg = styled(Svg)`
  margin-left: auto;

  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.49);
  font-size: 9px;

  transition: transform 150ms ease-in-out;
  transform: rotate(90deg);

  &.not-expandable {
    transform: rotate(0);
  }

  .active & {
    transform: rotate(-90deg);
  }
`;

const MenuItemBody = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 13px;

  &.regions,
  &.mediums,
  &.centuryRanges,
  &.priceRanges,
  &.categories {
    grid-template-columns: 1fr 1fr;
  }
  padding-bottom: 25px;
`;

const formButtons = css`
  margin-top: 30px;

  & + & {
    margin-top: 9px;
  }
`;

const Arrow = ({ className }: { className?: string }) => {
  return (
    <ArrowSvg className={className}>
      <SvgArrowTiny height="9" />
    </ArrowSvg>
  );
};

type SearchFiltersMode = "default" | "header";

export interface SearchFiltersMenuMobileProps {
  className?: string;
  onClose?: () => void;
  mode?: SearchFiltersMode;
}

const SearchFiltersMenuMobile = ({
  className,
  onClose,
  mode = "default",
}: SearchFiltersMenuMobileProps) => {
  const { filtersMenuItemsArray } = useFiltersMenu();
  const {
    handleFiltersChanged,
    handleClearFilters,
    handleResetFilters,
    handleRecentlyAddedChange
  } = useSearch();

  const filters = useSearchState();

  const { deepSearch, handleClearSearch } = useShallowSearch();

  const handleSubmit = useCallback(
    (values: Partial<SearchFilters>) => {
      if (mode === "header") {
        deepSearch({ ...values, name: undefined });
      } else {
        handleFiltersChanged(values);
      }
      onClose && onClose();
    },
    [deepSearch, handleFiltersChanged, mode, onClose]
  );

  const handleViewAll = useCallback(() => {
    handleClearSearch();
    handleResetFilters();
    setTimeout(() => deepSearch({ name: "" }));
    onClose && onClose();
  }, [deepSearch, handleClearSearch, handleResetFilters, onClose]);

  return (
    <Form initialValues={filters} onSubmit={handleSubmit}>
      {({ handleSubmit, form }) => (
        <StyledForm onSubmit={handleSubmit} className={className}>
          <ListItemWrapper>
            <button
              type="button"
              onClick={handleViewAll}
              className={listItemCss}
            >
              View All
              <Arrow className="not-expandable" />
            </button>
          </ListItemWrapper>
          <ListItemWrapper>
            <button
                type="button"
                onClick={handleRecentlyAddedChange}
                className={listItemCss}
              >
                {filters.recentlyAdded === "false" ? "Show recently added" : "Hide recently added"}
                <Arrow className="not-expandable" />
              </button>
          </ListItemWrapper>
          {filtersMenuItemsArray.map((filter) => (
            <ListItemWrapper key={filter.label}>
              <NestedMenuItem
                className={listItemCss}
                nestedItemKey={filter.filter}
                title={
                  <>
                    {filter.label} <Arrow />
                  </>
                }
              >
                <MenuItemBody className={filter.filter}>
                  {filter.items?.map((item) => (
                    <CheckboxLabel key={item.key}>
                      <Field
                        type="checkbox"
                        name={filter.filter}
                        component={Checkbox}
                        variant="filled"
                        value={item.key}
                      />
                      {item.label}
                    </CheckboxLabel>
                  ))}
                </MenuItemBody>
              </NestedMenuItem>
            </ListItemWrapper>
          ))}
          <Button className={clsx("secondary", "fullWidth", formButtons)}>
            APPLY FILTERS
          </Button>
          <Button
            type="button"
            onClick={() => {
              handleClearFilters();
              form.reset();
              onClose && onClose();
            }}
            className={clsx("fullWidth", formButtons)}
          >
            CLEAR
          </Button>
        </StyledForm>
      )}
    </Form>
  );
};

export default NestedMenuHOC<SearchFiltersMenuMobileProps>({
  singleExpanded: true,
})(SearchFiltersMenuMobile);
