import useConstants from "features/ServerConstants/hooks/useConstants";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { SearchFilters } from "../interfaces/search.interfaces";

export type FilterePanelKeys = keyof Omit<SearchFilters, "name">;

type FiltersArray = {
  filter: FilterePanelKeys;
  label: string;
  items?: { key: string; label: string; type: "checkbox" | "radio" }[];
};

type FiltersMap = Partial<
  Record<
    FilterePanelKeys,
    { key: string; label: string; type: "checkbox" | "radio" }[] | undefined
  >
>;

const defaultSortOptions: FiltersArray = {
  filter: "sortBy",
  label: "Sort By",
  items: [
    { key: "asc", label: "A-Z", type: "radio" },
    { key: "desc", label: "Z-A", type: "radio" },
  ],
};


export default function useFiltersMenu() {
  const { data } = useConstants();
  const location = useLocation()
  const [sort, setSort] = useState<FiltersArray>(defaultSortOptions);

  const sortType = useMemo(() => {
    const pathnameArr = location.pathname.split("/")
    
    if(pathnameArr.includes('artworks')) return 'artworks'
    else return 'vr'

    return pathnameArr
  }, [location])

  useEffect(() => {
    console.log(location)
    if (sortType === "artworks") {
      const sortOptions: FiltersArray = {
        filter: "orderBy",
        label: "Sort By",
        items: [
          { key: "Artist.lastName", label: "Artist Name (A-Z)", type: "radio" },
          { key: "name", label: "Artwork Name", type: "radio" },
          { key: "price", label: "Price", type: "radio" },
        ],
      };
      console.log(`this is artworks`)
      setSort(sortOptions);
    } else {
      const sortOptions: FiltersArray = {
        filter: "sortBy",
        label: "Sort By",
        items: [
          { key: "asc", label: "A-Z", type: "radio" },
          { key: "desc", label: "Z-A", type: "radio" },
        ],
      };
      setSort(sortOptions);
    }
  }, [setSort, sortType]);

  const filtersMenuItemsArray: FiltersArray[] = useMemo(
    () => [
      {
        filter: "sections",
        label: "Section",
        items: data?.Section.filter((i) => i.visible).map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      {
        filter: "regions",
        label: "Region",
        items: data?.Region.filter((i) => i.isActive).map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      {
        filter: "mediums",
        label: "Medium",
        items: data?.Medium.filter((i) => i.isActive).map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      {
        filter: "categories",
        label: "Category",
        items: data?.ArtworkCategory.filter((i) => i.isActive).map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      {
        filter: "priceRanges",
        label: "Price",
        items: data?.PriceRange.filter((i) => i.isActive).map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      {
        filter: "centuryRanges",
        label: "Date",
        items: data?.CenturyRange.filter((i) => i.isActive).map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      {
        filter: "years",
        label: "Year",
        items: data?.Year.map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      sort,
    ],
    [data, sort]
  );
  const filtersMenuItems = useMemo(() => {
    return filtersMenuItemsArray.reduce((acc, item) => {
      acc[item.filter] = item.items;
      return acc;
    }, {} as FiltersMap);
  }, [filtersMenuItemsArray]);
  return { filtersMenuItems, filtersMenuItemsArray };
}
